import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function ItalianCoffee(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Coffee-Menu" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Cà Phê Ý</h1>
          <p>Chúng tôi có đầy đủ các loại cà phê ý thơm ngon.</p>
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/espresso.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/espresso.png 500w, images/espresso.png 928w"
                  alt=""
                  class="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Espresso</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/americano.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/americano.png 500w, images/americano.png 928w"
                  alt=""
                  class="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Americano</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/latte.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/latte.png 500w, images/latte.png 928w"
                  alt=""
                  class="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Latte</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cappuccino.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/cappuccino.png 500w, images/cappuccino.png 928w"
                  alt=""
                  class="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Cappuccino</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/mocha.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/mocha.png 500w, images/mocha.png 928w"
                  alt=""
                  class="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Mocha</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/caramel.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcset="images/caramel.png 500w, images/caramel.png 928w"
                  alt=""
                  class="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Caramel Hạnh Nhân</strong>
                <div className="select-field w-select">45.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default ItalianCoffee;
