import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function Cake(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Our-Food" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Món Bánh</h1>
          <p>Chúng tôi có đầy đủ các loại bánh thơm ngon, nóng giòn.</p>
          <div className="w-layout-grid menu-grid our-food">
            <div className="menu-card">
              <div className="menu-card-title">BLUEBERRY</div>
              {/* <p className="small-p">39.000 đ</p> */}
              <div className="select-field w-select">39.000 đ</div>
              <img src="images/BlueberryMuffin-1024x1002-321x490.png" loading="lazy" width={200} alt="Vegan Food" />
              {/* <div className="menu-icon-wrapper">
                <img src="https://assets.website-files.com/60366f34aa47ec600cc3023c/6058a8823dcb19b46495978f_icons8_vegan_food.svg" loading="lazy" width={20} alt="Vegan Food" className="menu-icon" />
                <div>Vegan</div>
              </div> */}
            </div>
            <div className="menu-card">
              <div className="menu-card-title">CROISSANT</div>
              <div className="select-field w-select">39.000 đ</div>
              <img src="images/Croissant-1000x978.png" loading="lazy" width={200} alt="Vegan Food" />
            </div>
            <div className="menu-card">
              <div className="menu-card-title">CHOCOLATE</div>
              <div className="select-field w-select">39.000 đ</div>
              <img src="images/ChocolateMuffin-1000x978.png" loading="lazy" width={200} alt="Vegan Food" />
            </div>
            <div className="menu-card">
              <div className="menu-card-title">BÁNH MÌ QUE</div>
              <div className="select-field w-select">12.000 đ</div>
              <img src="images/walya-bakery.png" loading="lazy" width={200} alt="Vegan Food" />
              {/* <div className="menu-icon-wrapper">
                <img src="https://assets.website-files.com/60366f34aa47ec600cc3023c/6058a8823dcb19b46495978f_icons8_vegan_food.svg" loading="lazy" width={20} alt="Vegan Food" className="menu-icon" />
                <div>Vegan</div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default Cake;
