import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from './i18n/index';

import './App.css';
import Home from './components/home';
import Price from './components/our-stores';
import AboutUs from './components/about-us';
import Careers from './components/career';
import Contact from './components/contact';
// import LanguageSwitcher from './components/language'
import Qna from './components/qna';
import OurStores from './components/our-stores';
import News from './components/news';
import PageNotFound from './components/page-not-found-404';
import ItalianCoffee from './components/italian-cf';
import VietnameseCoffee from './components/vietnamese-cf';
import Tea from './components/tea';
import Juice from './components/juice';
import IceBlended from './components/ice-blended';
import Cake from './components/cake';

function App() {
  const { t } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <BrowserRouter forceRefresh={false}>
      <div className="banner">
        <div className="banner-container">
          <div className="banner-row">
            <div className="banner-column w-inline-block">
              <img src="fonts/604c6383690126296bcbdf1a_icons8_java_bean_1.svg" loading="lazy" alt="" className="banner-icon" />
              <div>Cà phê chất lượng cao</div>
            </div>
            {/* <a href="tel:+440207896786" className="banner-column w-inline-block">
              <div className="banner-icon-wrapper">
                <img src="fonts/604c687263ae4405310b5bf7_icons8_iphone_x_3.svg" alt="" className="banner-icon" />
                <img src="fonts/604c687263ae4405310b5bf7_icons8_iphone_x_3.svg" alt="" className="banner-icon" />
              </div>
              <div>090.710.6666</div>
            </a> */}
            <a href="mailto:hello@walyacoffee.com" className="banner-column w-inline-block">
              <div className="banner-icon-wrapper">
                <img src="fonts/email.svg" alt="" className="banner-icon" />
                <img src="fonts/email.svg" alt="" className="banner-icon" />
              </div>
              <div>hello@walyacoffee.com</div>
            </a>
          </div>
        </div>
      </div>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration={400}
        data-w-id="fe3faf11-8463-0f8a-85c8-2a3b54f6c774"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div className="nav-container">
          <Link to="/" aria-current="page" className="brand" style={{ width: '160px' }}>
            <img src="fonts/logo-walya.svg" loading="lazy" alt="Coffee Shop Logo" />
          </Link>
          <div className="nav-menu-wrapper">
            <nav role="navigation" className="nav-menu w-nav-menu">
              <div data-hover="true" data-delay={200} className="dropdown-wrapper w-dropdown">
                <div className="nav-link w-dropdown-toggle">
                  <div>Thực đơn</div>
                  <div
                    class="dropdown-lottie icon-arrow"
                    // data-is-ix2-target={1}
                    // className="dropdown-lottie"
                    // data-animation-type="lottie"
                    // data-src="./assets/6068db9135ae6e3bbca6b27d_lf30_editor_hk6pdjbx.json"
                    // data-loop={0}
                    // data-direction={1}
                    // data-autoplay={0}
                    // data-renderer="svg"
                    // data-default-duration="3.04"
                    // data-duration={0}
                    // data-ix2-initial-state={80}
                  />
                </div>
                <nav className="dropdown-list w-dropdown-list">
                  <Link to="/italian-coffee" aria-current="page" className="dropdown-link w-dropdown-link w--current">
                    Cà phê Ý
                  </Link>
                  <Link to="/vietnamese-coffee" className="dropdown-link w-dropdown-link">
                    Cà phê Việt
                  </Link>
                  <Link to="/tea" className="dropdown-link w-dropdown-link">
                    Trà
                  </Link>
                  <Link to="/ice-blended" className="dropdown-link w-dropdown-link">
                    Đá xay
                  </Link>
                  <Link to="/cake" className="dropdown-link w-dropdown-link">
                    Bánh
                  </Link>
                  <Link to="/juice" className="dropdown-link w-dropdown-link">
                    Nước ép và Sữa chua
                  </Link>
                </nav>
              </div>
              {/* <Link to="/our-menu" className="nav-link w-nav-link">
                Thực đơn
              </Link> */}
              {/* <a href="/service" className="nav-link w-nav-link">
                Dịch vụ
              </a> */}
              <Link to="/our-stores" className="nav-link w-nav-link">
                Cửa hàng
              </Link>
              <Link to="/news" className="nav-link w-nav-link">
                Tin tức
              </Link>
              <Link to="/about" className="nav-link w-nav-link">
                Về chúng tôi
              </Link>
              {/* <Link to="/careers" className="nav-link w-nav-link">
                Tuyển dụng
              </Link> */}
            </nav>
          </div>
          <div className="menu-button w-nav-button">
            <div
              class="hamburger" id="hamburger-1"
              // data-is-ix2-target={1}
              // className="hamburger-lottie"
              // data-animation-type="lottie"
              // data-src="./assets/605869cf120db14e31e7bf3b_lf30_editor_o1or3ms6.json"
              // data-loop={0}
              // data-direction={1}
              // data-autoplay={0}
              // data-renderer="svg"
              // data-default-duration={2}
              // data-duration={0}
              // data-ix2-initial-state={0}
            >
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/" element={<Home t={t} />} />
        <Route index element={<Home t={t} />} />
        <Route path="/our-menu" element={<Home t={t} />} />
        <Route path="/our-stores" element={<OurStores t={t} />} />
        <Route path="/news" element={<News t={t} />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/italian-coffee" element={<ItalianCoffee />} />
        <Route path="/vietnamese-coffee" element={<VietnameseCoffee />} />
        <Route path="/tea" element={<Tea />} />
        <Route path="/juice" element={<Juice />} />
        <Route path="/ice-blended" element={<IceBlended />} />
        <Route path="/cake" element={<Cake />} />
        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/our-menu/*" element={<PageNotFound />} />
        <Route path="/our-stores/*" element={<PageNotFound />} />
        <Route path="/news/*" element={<PageNotFound />} />
        <Route path="/about/*" element={<PageNotFound />} /> */}
      </Routes>
      <footer id="footer" className="footer wf-section">
        <div className="container">
          <div className="footer-flex-container">
          <div className="footer-column">
              <h2 className="footer-heading">Thực đơn</h2>
              <div className="footer-more-pages-grid">
                <ul role="list" className="w-list-unstyled">
                  <li>
                    <Link to="/italian-coffee" aria-current="page" className="footer-link">
                      Cà phê Ý
                    </Link>
                  </li>
                  <li>
                    <Link to="/vietnamese-coffee" className="footer-link">
                      Cà phê Việt
                    </Link>
                  </li>
                  <li>
                    <Link to="/tea" className="footer-link">
                      Trà
                    </Link>
                  </li>
                  <li>
                    <Link to="/ice-blended" className="footer-link">
                      Đá xay
                    </Link>
                  </li>
                  <li>
                    <Link to="/cake" className="footer-link">
                      Bánh
                    </Link>
                  </li>
                  <li>
                    <Link to="/juice" className="footer-link">
                      Nước ép và Sữa chua
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-column">
              <h2 className="footer-heading">Giới thiệu</h2>
              <div className="footer-more-pages-grid">
                <ul role="list" className="w-list-unstyled">
                  <li>
                    <Link to="/our-stores" className="footer-link">
                      Cửa Hàng
                    </Link>
                  </li>
                  <li>
                    <Link to="/news" className="footer-link">
                      Tin Tức
                    </Link>
                  </li>
                  {/* <li>
                    <a to="/careers" className="footer-link">
                      Tuyển Dụng
                    </a>
                  </li> */}
                  <li>
                    <Link to="/about" className="footer-link">
                      Về Chúng Tôi
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-column">
              <h2 className="footer-heading">Liên Hệ</h2>
              <ul role="list" className="w-list-unstyled">
                <li>
                  <a href="mailto:hello@walyacoffee.com" target="_blank" className="footer-link">
                    hello@walyacoffee.com
                  </a>
                </li>
                <li>
                  <a href="tel:+84907106666" className="footer-link">
                    090.710.6666
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-heading">Mạng Xã Hội</h2>
              <ul role="list" className="footer-social-list w-list-unstyled">
                <li>
                  <a href="http://www.twitter.com" target="_blank" className="footer-social-link w-inline-block">
                    <img
                      src="fonts/6048c1e6f7ef8f8267038111_icons8_twitter.svg"
                      loading="lazy"
                      alt="Twitter Logo"
                      className="footer-social-icon"
                    />
                  </a>
                </li>
                <li>
                  <a href="http://www.facebook.com" target="_blank" className="footer-social-link w-inline-block">
                    <img
                      src="fonts/6048c23aaa2882344e8c4ca2_icons8_facebook_f.svg"
                      loading="lazy"
                      alt="Facebook Logo"
                      className="footer-social-icon"
                    />
                  </a>
                </li>
                <li>
                  <a href="http://www.Instagram.com" target="_blank" className="footer-social-link w-inline-block">
                    <img
                      src="fonts/6048c24e7c3e9a65e90c2004_icons8_instagram.svg"
                      loading="lazy"
                      alt="Instagram Logo"
                      className="footer-social-icon"
                    />
                  </a>
                </li>
                <li>
                  <a href="http://www.tiktok.com" target="_blank" className="footer-social-link w-inline-block">
                    <img
                      src="fonts/6048c26859dae6e6849cde8a_icons8_tiktok.svg"
                      loading="lazy"
                      alt="Tik Tok Logo"
                      className="footer-social-icon"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="trust-card">
              <div>
                <img src="fonts/604a205a06cea4e9ddc52fbe_icons8_protect_1.svg" loading="lazy" alt="" className="trust-card-icon" />
                <div className="trust-card-title">Bảo mật thanh toán</div>
                <div className="trust-card-grid">
                  <img
                    src="images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem.png"
                    loading="lazy"
                    sizes="75px"
                    srcSet="images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-500.png 500w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-800.png 800w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-1080.png 1080w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-1600.png 1600w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-2000.png 2000w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-2600.png 2600w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem-p-3200.png 3200w, images/604a1a836662bc3919e76f4b_PayPal_logo_logotype_emblem.png 5000w"
                    alt="PayPal Logo"
                    className="trust-card-logo"
                  />
                  <img
                    src="fonts/604a1e7abac9d18cad12bccb_Google%20Pay%20Logo.svg"
                    loading="lazy"
                    alt="Google Pay Logo"
                    className="trust-card-logo"
                  />
                  <img
                    src="fonts/604a1e7aebaed2530c4e5e55_Apple%20Pay%20Logo.svg"
                    loading="lazy"
                    alt="Apple Pay Logo"
                    className="trust-card-logo"
                  />
                  <img
                    src="fonts/604a1ed801af116f97709f16_mc_vrt_pos.svg"
                    loading="lazy"
                    alt="Mastercard Logo"
                    className="trust-card-logo"
                  />
                  <img
                    src="images/604a1f33a862ecafa0ec33f3_full-color-800x450.jpg"
                    loading="lazy"
                    sizes="(max-width: 1279px) 53.33124923706055px, (max-width: 1439px) 4vw, 53.33124923706055px"
                    srcSet="images/604a1f33a862ecafa0ec33f3_full-color-800x450-p-500.jpeg 500w, images/604a1f33a862ecafa0ec33f3_full-color-800x450.jpg 800w"
                    alt="Visa Logo"
                    className="trust-card-logo"
                  />
                </div>
              </div>
              <div>
                Copyright © WALYA Ltd. All rights reserved.
                <br />
                Phát triển và vận hành bởi{' '}
                <a href="http://sitech.asia/" target="_blank">
                  Sitech Asia
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </BrowserRouter>
  );
}

export default App;
