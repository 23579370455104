import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function Home(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <div className="home-hero-section wf-section">
        <div
          data-delay={5000}
          data-animation="cross"
          className="hero-slider w-slider"
          data-autoplay="true"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit={0}
          data-nav-spacing={3}
          data-duration={500}
          data-infinite="true"
        >
          <div className="w-slider-mask">
            <div className=" w-slide">
              <div className="hero-slider-slide-content">
                <div className="slider-1 hero-slider-video w-background-video w-background-video-atom">
                </div>
              </div>
            </div>
            <div className="w-slide">
              <div className="hero-slider-slide-content">
                <div className="slider-2 hero-slider-video w-background-video w-background-video-atom">
                </div>
              </div>
            </div>
          </div>
          <div className="left-arrow w-slider-arrow-left">
            <div className="w-icon-slider-left" />
          </div>
          <div className="right-arrow w-slider-arrow-right">
            <div className="w-icon-slider-right" />
          </div>
          <div className="slide-nav w-slider-nav w-round" />
        </div>
      </div>
      <section id="Other-Drinks" className="section-best-seller wf-section">
        <div className="container">
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/cfdenkemmuoi.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/cfdenkemmuoi.png 500w, images/cfdenkemmuoi.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong className="drink-name">Cà Phê Đen Kem Muối</strong>
                <div className="select-field w-select">42.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/oreo-cake.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/oreo-cake.png 500w, images/oreo-cake.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong className="drink-name">Oreo Cake</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cantay-taogung.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/cantay-taogung.png 500w, images/cantay-taogung.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong className="drink-name">Cần Tây Táo Gừng</strong>
                <div className="select-field w-select">49.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/walya-cake.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/walya-cake.png 500w, images/walya-cake.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong className="drink-name">Bánh Mì Que</strong>
                <div className="select-field w-select">12.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="banner-section wf-section">
        <div className="banner-section-1"/>
      </div>
      <div className="banner-section wf-section">
        <div className="banner-section-2" loading="lazy" />
      </div>
      <div className="banner-section wf-section">
        <div className="banner-section-3" loading="lazy" />
      </div>
      <Store/>
    </>
  );
}

export default Home;
