import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

function News() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <div className="section our-coffee-v3 wf-section">
        <div className="container">
          <div className="w-layout-grid journal-page-grid">
            <div>
              <div className="w-dyn-list">
                <div role="list" className="journal-page-list w-dyn-items">
                  <div role="listitem" className="journal-item w-dyn-item">
                    <a href="#" className="journal-link w-inline-block">
                      <div className="journal-item-date">25/03/2023</div>
                      <div className="journal-item-title">Hướng dẫn pha cà phê trong bình Moka Pot của bạn</div>
                      <p className="journal-item-summary">
                        Hướng dẫn nhanh chóng và dễ dàng để pha cà phê trong Moka Pot truyền thống của Ý. Thưởng thức cà phê tuyệt vời tại
                        nhà.
                      </p>
                    </a>
                    <div
                      style={{
                        backgroundImage:
                          'url("https://assets.website-files.com/60460c8d35f3581d68d3ee1c/6058803d835c10416bec4882_smartmockups_kmki6o49_thumb.jpg")',
                      }}
                      className="journal-item-image"
                    />
                  </div>
                  <div role="listitem" className="journal-item w-dyn-item">
                    <a href="#" className="journal-link w-inline-block">
                      <div className="journal-item-date">25/03/2023</div>
                      <div className="journal-item-title">Cold Brew là gì?</div>
                      <p className="journal-item-summary">
                        Một sự bổ sung độc đáo cho dòng cà phê lạnh, Cold Brew là loại đồ uống làm từ cà phê duy nhất được pha chế mà không
                        cần pha cà phê espresso.
                      </p>
                    </a>
                    <div
                      style={{
                        backgroundImage:
                          'url("https://assets.website-files.com/60460c8d35f3581d68d3ee1c/60587cf5d09759f735deec7f_pexels-charlotte-may-5947094.jpg")',
                      }}
                      className="journal-item-image"
                    />
                  </div>
                  <div role="listitem" className="journal-item w-dyn-item">
                    <a href="#" className="journal-link w-inline-block">
                      <div className="journal-item-date">25/03/2023</div>
                      <div className="journal-item-title">Hướng dẫn pha cà phê Aeropress hoàn hảo</div>
                      <p className="journal-item-summary">
                        Hướng dẫn nhanh chóng và dễ dàng của bạn về cách pha cà phê bằng máy Aeropress tại nhà hoặc bất kỳ đâu.
                      </p>
                    </a>
                    <div
                      style={{
                        backgroundImage:
                          'url("https://assets.website-files.com/60460c8d35f3581d68d3ee1c/60587ae16aaeb4685e082864_pexels-gareth-rees-4787479.jpg")',
                      }}
                      className="journal-item-image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="w-node-_247936fd-840a-3b57-89f4-d739a48d3f06-41667e3a" className="sticky-wrapper">
              <div className="aside-sign-up-form w-form">
                <form id="wf-form-Sign-Up-Aside-Form" name="wf-form-Sign-Up-Aside-Form" data-name="Sign Up Aside Form" method="get">
                  <div className="aside-sign-up-title">Nhận khuyến mãi và bài viết mới từ chúng tôi</div>
                  <label htmlFor="name">Tên</label>
                  <input
                    type="text"
                    className="text-field aside w-input"
                    maxLength={256}
                    name="name-2"
                    data-name="Name 2"
                    placeholder
                    id="name-2"
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="text-field aside w-input"
                    maxLength={256}
                    name="email-2"
                    data-name="Email 2"
                    placeholder
                    id="email-2"
                    required
                  />
                  <input type="submit" defaultValue="Đăng ký" data-wait="Vui lòng đợi..." className="button form w-button" />
                </form>
                <div className="success-message aside w-form-done">
                  <img
                    src="https://assets.website-files.com/60366f34aa47ec600cc3023c/6048ace60cb9a3c58b7aecf2_icons8_important_mail.svg"
                    loading="lazy"
                    width={30}
                    alt=""
                  />
                  <div className="success-message-title">You're on the list!</div>
                  <p>Don't forget to check your inbox and confirm your subscription.</p>
                </div>
                <div className="error-state w-form-fail">
                  <div>Something went wrong!?</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container crest-container">
        <img src="fonts/6047437a9190494d15914da8_Crest%20Teal.svg" loading="lazy" alt="" className="crest-section-splitter" />
      </div> */}
    </>
  );
}

export default News;
