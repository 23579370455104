import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function AboutUs() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <div className="home-hero-section-v2 wf-section">
        <div className="home-hero-container-version-2">
          <h1 className="hero-slider-h1 v2">Tinh tế đến từng hạt cà phê</h1>
          <p className="hero-slider-subtitle">
            Chúng tôi luôn đặt chất lượng và trải nghiệm của khách hàng lên hàng đầu!
            <br />
          </p>
        </div>
        <div
          data-poster-url="images/60695eaa1e205c498ba54400_production ID_4822122-poster-00001.jpg"
          data-video-urls="images/60695eaa1e205c498ba54400_production ID_4822122-transcode.mp4,images/60695eaa1e205c498ba54400_production ID_4822122-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          className="home-hero-video-v2 w-background-video w-background-video-atom"
        >
          <video
            id="515c080f-12d5-6093-d585-ed0a9afb7fdd-video"
            autoPlay
            loop
            style={{
              backgroundImage: 'url("images/60695eaa1e205c498ba54400_production ID_4822122-poster-00001.jpg")',
            }}
            muted
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
          >
            <source src="images/60695eaa1e205c498ba54400_production ID_4822122-transcode.mp4" data-wf-ignore="true" />
            <source src="images/60695eaa1e205c498ba54400_production ID_4822122-transcode.webm" data-wf-ignore="true" />
          </video>
          <div className="hero-slider-video-overlay" />
        </div>
      </div>
      <div className="container crest-container">
        <img src="fonts/6047437a9190494d15914da8_Crest%20Teal.svg" loading="lazy" alt="" className="crest-section-splitter" />
      </div>
      <div className="section our-coffee-v3 wf-section">
        <div className="container">
          {/* <h1 className="page-title">GIỚI THIỆU VỀ WALYA COFFEE</h1> */}
          <p className="page-subtitle">
            WALYA Coffee là hệ thống cà phê với các lĩnh vực chính như cung cấp máy pha cà phê, các loại cà phê hạt và cà phê bột, chuỗi cửa
            hàng cà phê và nhượng quyền thương hiệu.
          </p>
          <div className="w-layout-grid contact-grid">
            <div>
              <h3 className="contact-h3">Không gian và nhân viên tại WALYA Coffee</h3>
              <div className="w-dyn-list">
                <div role="list" className="contact-page-location-list w-dyn-items">
                  <div role="listitem" className="location-item w-dyn-item">
                    <div className="location-item-row contact-page">
                      <img
                        src="images/company_activities_4.jpeg"
                        data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                        alt="Latte"
                      />
                    </div>
                  </div>
                  <div role="listitem" className="location-item w-dyn-item">
                    <div className="location-item-row contact-page">
                      <img
                        src="images/company_activities_2.jpeg"
                        data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                        alt="Latte"
                      />
                    </div>
                  </div>
                  <div role="listitem" className="location-item w-dyn-item">
                    <div className="location-item-row contact-page">
                      <img
                        src="images/company_activities_3.jpeg"
                        data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                        alt="Latte"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="w-node-_181c5ee5-09e8-c09e-1edb-c543dc1068a5-c7c773a7">
              <h3 className="contact-h3">Liên hệ</h3>
              <div className="location-item-row contact-page">
                <div>
                  <div className="location-item-title">Trụ sở chính</div>
                  <div className="location-details-link dark">
                    <img src="fonts/6048c02081d85f2b9c9edbb8_icons8_marker_1.svg" loading="lazy" alt="" className="google-maps-icon" />
                    <div>Văn phòng đại diện chi nhánh phía nam: D54 Đường D1, P. Phú Mỹ, Quận 7, TP. HCM</div>
                  </div>
                  <a href="tel:+0907106666" className="location-details-link dark w-inline-block">
                    <img src="fonts/6048c00c055bad5e8a0573d6_icons8_iphone_x_1.svg" loading="lazy" alt="" className="google-maps-icon" />
                    <div>090 710 6666</div>
                  </a>
                  <a href="mailto:hello@walyacoffee.com" className="location-details-link dark w-inline-block">
                    <img
                      src="fonts/6048bff97c3e9a44210c16d6_icons8_important_mail_1.svg"
                      loading="lazy"
                      alt=""
                      className="google-maps-icon"
                    />
                    <div>hello@walyacoffee.com</div>
                  </a>
                </div>
              </div>
              <h3 className="contact-h3">WALYA trên mạng xã hội</h3>
              <div className="w-layout-grid social-media-grid">
                <a href="http://www.twitter.com" target="_blank" className="social-grid-link w-inline-block">
                  <img
                    src="fonts/6048c1e6f7ef8f8267038111_icons8_twitter.svg"
                    loading="lazy"
                    alt="Twitter Logo"
                    className="social-link-icon"
                  />
                </a>
                <a href="http://www.facebook.com" target="_blank" className="social-grid-link w-inline-block">
                  <img
                    src="fonts/6048c23aaa2882344e8c4ca2_icons8_facebook_f.svg"
                    loading="lazy"
                    alt="Facebook Logo"
                    className="social-link-icon"
                  />
                </a>
                <a href="http://www.instagram.com" className="social-grid-link w-inline-block">
                  <img
                    src="fonts/6048c24e7c3e9a65e90c2004_icons8_instagram.svg"
                    loading="lazy"
                    alt="Instagram Logo"
                    className="social-link-icon"
                  />
                </a>
                <a href="http://www.tiktok.com" className="social-grid-link w-inline-block">
                  <img
                    src="fonts/6048c26859dae6e6849cde8a_icons8_tiktok.svg"
                    loading="lazy"
                    alt="Tik Tok Logo"
                    className="social-link-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Store/>
    </>
  );
}

export default AboutUs;
