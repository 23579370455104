import React, { useEffect } from 'react';

function PageNotFound() {

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
  });

  return (
    <>
      <div className="utility-page-wrap">
        <div className="utility-page-content w-form">
          <h1 className="_404-title">404</h1>
          <h2>Oops! Trang hiện không tồn tại</h2>
          <p>
            Trang bạn đang tìm kiếm không tồn tại, bị xoá hoặc thông tin đã bị thay đổi.<a href="/">Về trang chủ</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
