import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function Juice(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Other-Drinks" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Nước ép và Sữa chua</h1>
          <p>Chúng tôi có đầy đủ các loại nước ép và sữa chua Healthy.</p>
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/oi.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/oi.png 500w, images/oi.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Ổi</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cam.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/cam.png 500w, images/cam.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Cam</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cantay-taogung.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/cantay-taogung.png 500w, images/cantay-taogung.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Cần Tây Táo Gừng</strong>
                <div className="select-field w-select">49.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/tao-cuden-carot.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/tao-cuden-carot.png 500w,
                images/tao-cuden-carot.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Táo Củ Dền Cà Rốt</strong>
                <div className="select-field w-select">49.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/thom.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/thom.png 500w,
                images/thom.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Thơm</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/suachua-dao-dac-thom.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/suachua-dao-dac-thom.png 500w, images/suachua-dao-dac-thom.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Sữa Chua Đào Đác Thơm</strong>
                <div className="select-field w-select">42.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/suachua-dac-thom.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/suachua-dac-thom.png 500w, images/suachua-dac-thom.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Sữa Chua Đác Thơm</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/chanhda.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/chanhda.png 500w,
                images/chanhda.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Chanh Đá</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default Juice;
