const vi = {
    header: {
        nav: {
            home: 'Trang chủ',
            pricing: 'Bảng giá',
            about: 'Về chúng tôi',
            contact: 'Liên hệ',
            careers: 'Cơ hội nghề nghiệp',
            qna: 'Câu hỏi',
            template: 'Khám phá mẫu'
        }
    },
    hero: {
        heading: 'Tăng truy cập cho',
        subheading: 'website của bạn!',
        cta: 'Các trang web đẹp và thông minh của chúng tôi được thiết kế với SEO. Bạn sẵn sàng để làm điều đó?',
        startbutton: 'Bắt đầu ở đây',
        intro: {
            business: 'Doanh nghiệp của bạn sẽ được nhìn thấy bởi những người đúng!',
            personal: 'Bạn muốn một cái gì đó đặc biệt?',
            company: 'Chúng tôi làm cho nó siêu nhanh!'
        }
    },
    intro: {
        heading: 'Chuyên gia thiết kế hiện đại',
        cta: 'Đội ngũ thiết kế UX/UI và nhà phát triển web chuyên nghiệp của chúng tôi tận tâm tạo ra các giải pháp tùy chỉnh, giúp trình diễn đầy đủ tính cách và tầm nhìn độc đáo của thương hiệu bạn.',
        showcase: {
            customer: 'Khách hàng đăng ký',
            growth: 'Tăng trưởng kinh doanh',
            country: 'Quốc gia hoạt động'
        },
        slide: {
            top: {
                1: 'Thiết kế hiện đại',
                2: 'Tính đáp ứng di động',
                3: 'Tiếp cận khách hàng trung tâm',
                4: 'Giải pháp tùy chỉnh',
                5: 'Bảo mật doanh nghiệp',
                6: 'Phân tích nâng cao'
            },
            bottom: {
                1: 'Tốc độ tải nhanh',
                2: 'Giải pháp sáng tạo',
                3: 'Tối ưu hóa máy tìm kiếm (SEO)',
                4: 'Hệ thống quản lý nội dung (CMS)',
                5: 'Cam kết chất lượng',
                6: 'Hỗ trợ 24/7',
                7: 'Tích hợp với AWS'
            }
        },
        detail: {
            heading: 'Cần biết những gì bạn nhận được?',
            cta1: 'Bạn muốn tìm hiểu về các dịch vụ mà chúng tôi cung cấp? Chúng tôi hiểu rằng đầu tư vào một sản phẩm hoặc dịch vụ là một quyết định lớn, do đó chúng tôi muốn minh bạch về những gì bạn có thể mong đợi.',
            cta2: 'Đội ngũ của chúng tôi tạo ra các thiết kế tuyệt đẹp phù hợp với tất cả các loại doanh nghiệp. Chúng tôi sẽ hướng dẫn bạn thông qua quá trình thiết kế và phát triển của chúng tôi, cũng như cung cấp một danh sách chi tiết các tính năng được bao gồm trong gói của bạn.',
            cta3: 'Ngoài ra, chúng tôi còn cung cấp hỗ trợ và bảo trì liên tục để đảm bảo trang web của bạn hoạt động mượt mà trong thời gian dài.',
            startbutton: 'Phát triển doanh nghiệp của bạn ngay bây giờ!'
        }
    },
    services: {
        heading: 'CÁC TÍNH NĂNG',
        subheading: 'Phát triển doanh nghiệp của bạn cùng chúng tôi ngay hôm nay!',
        service1: {
            name: 'Landing page',
            subtitle: 'Phát triển nhanh',
            description: 'Chuyển đổi khách truy cập thành khách hàng, với thông điệp rõ ràng và các lời kêu gọi hành động hiệu quả.'
        },
        service2: {
            name: 'Thiết kế và phát triển',
            subtitle: 'Xây dựng ước mơ của bạn',
            description: 'Chúng tôi sử dụng các công cụ và công nghệ mới nhất.'
        },
        service3: {
            name: 'Tối ưu hóa công cụ tìm kiếm',
            subtitle: 'Tối ưu hóa SEO',
            description: 'Trang web của chúng tôi được tối ưu hóa cho các công cụ tìm kiếm.'
        },
        service4: {
            name: 'Đáp ứng trên mọi thiết bị',
            subtitle: 'Mọi thiết bị',
            description: 'Trang web của bạn sẽ được thiết kế tuyệt đẹp và hoạt động hoàn hảo trên mọi thiết bị.'
        },
        service5: {
            name: 'Lưu trữ an toàn',
            subtitle: 'Tích hợp AWS',
            description: 'Được bảo vệ bởi giám sát và hỗ trợ 24/7.'
        },
        service6: {
            name: 'Hệ thống quản lý nội dung',
            subtitle: 'Dữ liệu trên bảng điều khiển',
            description: 'Dễ dàng cập nhật và quản lý nội dung trang web của bạn.'
        },
        service7: {
            name: 'Tích hợp mạng xã hội',
            subtitle: 'Tăng lượng truy cập',
            description: 'Tích hợp tài khoản mạng xã hội của bạn để tăng tương tác và tăng lượng truy cập.'
        },
        service8: {
            name: 'Đa ngôn ngữ',
            subtitle: 'Hỗ trợ',
            description: 'Chúng tôi hỗ trợ đa ngôn ngữ và dịch vụ dịch thuật để đến với khách hàng toàn cầu của bạn.'
        },
        service9: {
            name: 'Tích hợp Chatbot AI',
            subtitle: 'Hỗ trợ',
            description: 'Chatbot AI cung cấp hỗ trợ và tương tác với khách hàng một cách liền mạch.'
        },
        ourteam: 'Đội ngũ thiết kế viên và nhà phát triển chuyên nghiệp của chúng tôi cam kết tạo ra các giải pháp tùy chỉnh thể hiện cá tính và tầm nhìn độc đáo của thương hiệu của bạn.'
    },
    review: {
        star: '5.0 Sao',
        title: 'Được các nhà lãnh đạo toàn cầu tin tưởng về giải pháp thiết kế và phát triển web chuyên nghiệp của chúng tôi.',
    },
    about: {
        heading: 'Về chúng tôi',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan ante ut ante pharetra mattis. Aenean commodo augue quis nulla volutpat, ut consequat urna feugiat. Suspendisse hendrerit lorem nec justo ultricies, ut bibendum dolor vestibulum. Praesent quis elit nec nunc tincidunt fringilla. Suspendisse eget purus in felis cursus eleifend sit amet eget turpis. Morbi sed sapien lectus. Donec eget ex at sapien accumsan ultricies id a nibh. Duis congue viverra libero, id efficitur diam aliquet id. '
    },
    book: {
        heading: 'Đầu tư thông minh nhất cho sự phát triển kinh doanh của bạn!',
        subheading: 'Hãy đặt chúng tôi ngay hôm nay và để chúng tôi giúp bạn đạt được mục tiêu của mình.',
        button: 'Đặt chúng tôi tại đây!'
    },
    qna: {
        heading: 'Answers to our most frequently asked questions',
        q1: 'What is the starting price of ainfluencer campaing?',
        a1: 'answer',
        q2: 'What social platform do yourinfluencers use?',
        a2: 'answer',
        q3: 'Have you worked with anylarge reputable brands?',
        a3: 'answer',
        q4: 'What is your process of building a campaign?',
        a4: 'answer'
    },
    footer: {
        heading: 'Tiếp thị kinh doanh của bạn',
        subheading1: 'Tạo một sự hiện diện mạnh mẽ trên mạng với các trang web và landing page thông minh của chúng tôi.',
        button: 'Phát triển kinh doanh của bạn ngay bây giờ!',
        subheading2: 'Thiết kế của chúng tôi ưu tiên trải nghiệm người dùng để giúp bạn nổi bật hơn so với đối thủ.',
        question: 'Bạn có câu hỏi nào không?',
        page: 'Trang',
        company: 'Công ty',
        subquestion: 'Hãy thoải mái! Hỏi chúng tôi bất cứ điều gì liên quan đến dịch vụ của chúng tôi',
        address: '107 Nguyen Van Linh St, D.7, HCM City',
        phone: '+84 973 898 494',
        email: 'hello@sitech.com',
        social: {
            facebook: 'Facebook',
            twitter: 'Twitter',
            instagram: 'Instagram'
        }
    }
};

export default vi;  