import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

function OurStores() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <div className="section locations-cta wf-section">
        <div className="container">
          <h1 className="page-title" style={{color:'white'}}>Hệ thống cửa hàng</h1>
          <p className="page-subtitle">Tìm quán cà phê gần nhất của bạn và thưởng thức nha 😋</p>
          <div className="location-list-wrapper w-dyn-list">
            <div role="list" className="locations-list w-dyn-items">
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                      'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">ECO GREEN</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">107 Đ. Nguyễn Văn Linh, Tân Thuận Tây, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/dUgDY5scKKcBdTPd9" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">PHÚ MỸ</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Đường Hoàng Quốc Việt, Phú Mỹ, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/ypmkimzYsfVPUEM57" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">A5 ORI</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Tầng G, Block A5 P, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/GPneEuJ2fHQ8SqUq9" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">E5 ERA TOWN</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">E5 Tầng G, Block B2, chung cư era town đường 15b, Phú Mỹ, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/6J2MswGCFpFMo9a6A" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">B2 ERA TOWN</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">B2 Tầng G, Block B2, chung cư era town đường 15b, Phú Mỹ, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/6J2MswGCFpFMo9a6A" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">RIVERSIDE</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">04 Đ. Đ6, Phú Thuận, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/AgWa4AUVkaT5XCV96" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">SKYLINE</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">48 Đ. Lê Thị Chợ, Phú Mỹ, Quận 7</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/19Hf3EyLtXsAQ5Ws8" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a
                    style={{
                      backgroundImage:
                        'url("images/walya-phumy.jpeg")',
                    }}
                    href="/locations/coventry"
                    className="location-image w-inline-block"
                  >
                    <div className="location-open-soon-title w-condition-invisible">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA COFFEE</div>
                    <div className="location-item-title-store-nav">TÂN HƯƠNG</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">36 Tân Hương, Tân Quý, Tân Phú</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Giờ mở cửa</div>
                      <div className="opening-soon-text w-dyn-bind-empty" />
                      <div className="w-dyn-bind-empty" />
                    </div>
                    <div className="location-item-rich-text w-richtext">
                      <p>Hằng ngày - 6:30 sáng - 10:30 tối</p>
                    </div>
                    <div className="subtle-text-link-wrapper">
                      <a href="https://goo.gl/maps/ik7pEUyArCExQCaMA" className="subtle-text-link w-inline-block" target="_blank">
                        <img
                          src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                          loading="lazy"
                          alt=""
                          className="google-maps-icon"
                        />
                        <div>Xem trên Google maps</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div role="listitem" className="location-item w-dyn-item">
                <div className="location-item-row">
                  <a style={{ backgroundImage: 'none' }} href="/locations/westbrook" className="location-image w-inline-block">
                    <div className="location-open-soon-title">Opening Soon</div>
                  </a>
                  <div>
                    <div className="location-item-title">WALYA Đà Nẵng</div>
                    <div className="opening-soon-row">
                      <div className="opening-soon-text fade">Opening</div>
                      <div className="opening-soon-text">October 28, 2021</div>
                      <div className="opening-soon-text fade">at</div>
                      <div>9:00 am</div>
                    </div>
                    <div className="location-item-rich-text w-condition-invisible w-richtext">
                      <p>Monday to Friday - 7am to 7pm</p>
                      <p>Saturday to Sunday - 8am to 6pm</p>
                    </div>
                    <a href="#" className="location-details-link w-inline-block">
                      <img
                        src="fonts/6048a8b56fa450d9d0469146_icons8_marker.svg"
                        loading="lazy"
                        alt=""
                        className="google-maps-icon"
                      />
                      <div>View on Google maps</div>
                    </a>
                    <a href="mailto:westbrook@barebeans.com" className="location-details-link w-inline-block">
                      <img
                        src="fonts/6048ace60cb9a3c58b7aecf2_icons8_important_mail.svg"
                        loading="lazy"
                        alt=""
                        className="google-maps-icon"
                      />
                      <div>westbrook@barebeans.com</div>
                    </a>
                    <a href="tel:+1163774432" className="location-details-link w-inline-block">
                      <img
                        src="fonts/6048acf195d3bacac4b61b3d_icons8_iphone_x.svg"
                        loading="lazy"
                        alt=""
                        className="google-maps-icon"
                      />
                      <div>+1 163 774 432</div>
                    </a>
                    <div className="subtle-text-link-wrapper">
                      <a href="/locations/westbrook" className="subtle-text-link w-inline-block" target="_blank">
                        <div>More details</div>
                        <img
                          src="fonts/604627d27d25be971583143d_icons8_right_arrow_1.svg"
                          loading="lazy"
                          alt="Right Pointing Arrow"
                          className="subtle-text-link-arrow"
                          style={{
                            transform:
                              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                            transformStyle: 'preserve-3d',
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurStores;
