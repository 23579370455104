import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

function Store() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <div className="section green wf-section">
        <div className="container">
          <div className="w-layout-grid locations-cta-grid">
            <div id="w-node-_472c68f5-89ac-0c28-ba0d-a526bb49eb7b-d1c3023d" className="sticky-wrapper">
              <h2 className="no-margin-top">Cửa hàng của chúng tôi</h2>
              <p>
                Chúng tôi tự hào là chuỗi thức uống chất lượng với chuỗi hệ thống cửa hàng tại TPHCM.
              </p>
              <p>
                Chúng tôi luôn nỗ lực vì khách hàng để mang tới sản phẩm và dịch vụ tốt nhất cho quý khách hàng.
              </p>
            </div>
            <div className="w-dyn-list">
              <div role="list" className="home-location-list w-dyn-items">
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/ypmkimzYsfVPUEM57" target="_blank" className="location-item-title-store">
                        PHÚ MỸ
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">Đường Hoàng Quốc Việt, Phú Mỹ, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/dUgDY5scKKcBdTPd9" target="_blank" className="location-item-title-store">
                        ECO GREEN
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">107 Đ. Nguyễn Văn Linh, Tân Thuận Tây, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/6J2MswGCFpFMo9a6A" target="_blank" className="location-item-title-store">
                        B2 ERA TOWN
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">B2 Tầng G, Block B2, chung cư Era town, Phú Mỹ, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/6J2MswGCFpFMo9a6A" target="_blank" className="location-item-title-store">
                        E5 ERA TOWN
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">E5 Tầng G, Block B2, chung cư Era town, Phú Mỹ, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/GPneEuJ2fHQ8SqUq9" target="_blank" className="location-item-title-store">
                        A5 ORI
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">Tầng G, Block A5 P, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/AgWa4AUVkaT5XCV96" target="_blank" className="location-item-title-store">
                        RIVERSIDE
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">04 Đ. Đ6, Phú Thuận, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/19Hf3EyLtXsAQ5Ws8" target="_blank" className="location-item-title-store">
                        SKYLINE
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">48 Đ. Lê Thị Chợ, Phú Mỹ, Quận 7</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="location-item-store w-dyn-item">
                  <div className="location-item-row">
                    <div>
                      <a href="https://goo.gl/maps/ik7pEUyArCExQCaMA" target="_blank" className="location-item-title-store">
                        TÂN HƯƠNG
                      </a>
                      <div className="opening-soon-row">
                        <div className="opening-soon-text fade">36 Tân Hương, Tân Quý, Tân Phú</div>
                        <div className="opening-soon-text w-dyn-bind-empty" />
                        <div className="w-dyn-bind-empty" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Store;
