const en = {
    header: {
        nav: {
            home: 'Home',
            pricing: 'Pricing',
            about: 'About Us',
            contact: 'Contact',
            careers: 'Careers',
            qna: 'FAQs',
            template: 'Explore Template'
        }
    },
    hero: {
        heading: 'Get traffic to your',
        subheading: 'clever website!',
        cta: 'Our beautiful, clever websites are designed with SEO. Are you ready to take it?',
        startbutton: 'Get started here',
        intro: {
            business: 'Your business will be seen by the right people!',
            personal: 'You want something special?',
            company: 'We make it super-fast!'
        }
    },
    intro: {
        heading: 'Modern design expertise',
        cta: 'Our team of expert UX/UI designers and web developers are dedicated to crafting custom solutions that showcase your brand\'s unique personality and vision.',
        showcase: {
            customer: 'Registered Customers',
            growth: 'Business Growth',
            country: 'Operating Countries'
        },
        slide: {
            top: {
                1: 'Modern Designs',
                2: 'Mobile Responsiveness',
                3: 'Customer-Centric Approach',
                4: 'Customized Solutions',
                5: 'Business Security',
                6: 'Advanced Analytics'
            },
            bottom: {
                1: 'Fast Loading Speed',
                2: 'Creative Solutions',
                3: 'Search Engine Optimization (SEO)',
                4: 'Content Management System (CMS)',
                5: 'Quality Commitment',
                6: '24/7 Support',
                7: 'Integrated with AWS'
            }
        },
        detail: {
            heading: 'Need to know what you get?',
            cta1: 'Are you curious about what our services entail? We know that investing in a product or service is a big decision, which is why we want to be transparent about what you can expect.',
            cta2: 'Our team creates stunning designs that cater to all types of businesses. We\'ll walk you through our design and development process, as well as provide a detailed list of features included in your package.',
            cta3: 'Additionally, we offer ongoing support and maintenance to ensure your website runs smoothly over time.',
            startbutton: 'Grow your business now!'
        }
    },
    services: {
        heading: 'FEATURE BREAKDOWN',
        subheading: 'Make Your Business With Us Today!',
        service1: {
            name: 'Landing page',
            subtitle: 'Quick develop',
            description: 'Convert visitors into customers, with clear messaging and effective calls to action.'
        },
        service2: {
            name: 'Design and development',
            subtitle: 'Build your dream',
            description: 'We use the latest tools and technologies.'
        },
        service3: {
            name: 'Search engine optimization',
            subtitle: 'SEO in mind',
            description: 'Our websites are optimized for search engines.'
        },
        service4: {
            name: 'Responsive for all devices',
            subtitle: 'Any device',
            description: 'Your website will look great and function perfectly on any device.'
        },
        service5: {
            name: 'Secure hosting',
            subtitle: 'AWS integration',
            description: 'Protected with 24/7 monitoring and support.'
        },
        service6: {
            name: 'Content management system',
            subtitle: 'Data in dashboard',
            description: 'Easily update and manage your website\'s content.'
        },
        service7: {
            name: 'Social media integration',
            subtitle: 'Drive traffic',
            description: 'Integrate with your social media accounts to boost engagement and drive traffic.'
        },
        service8: {
            name: 'Multi-languages',
            subtitle: 'Support',
            description: 'Attract your global customers with multi-language support and translation services.'
        },
        service9: {
            name: 'AI Chatbot integration',
            subtitle: 'Support',
            description: 'AI chatbots provide seamless customer support and engagement.'
        },
        ourteam: '*Our team of expert designers and developers are dedicated to crafting custom solutions that showcase your brand\'s uniquepersonality and vision*'
    },
    review: {
        star: '5.0 Stars',
        title: 'Trusted by global leaders for our expert web design and development solutions.',
    },
    about: {
        heading: 'About Us',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan ante ut ante pharetra mattis. Aenean commodo augue quis nulla volutpat, ut consequat urna feugiat. Suspendisse hendrerit lorem nec justo ultricies, ut bibendum dolor vestibulum. Praesent quis elit nec nunc tincidunt fringilla. Suspendisse eget purus in felis cursus eleifend sit amet eget turpis. Morbi sed sapien lectus. Donec eget ex at sapien accumsan ultricies id a nibh. Duis congue viverra libero, id efficitur diam aliquet id. '
    },
    book: {
        heading: 'The smartest investment for your business growth!',
        subheading: 'Book us today and let us help you achieve your goals.',
        button: 'Book us here!'
    },
    qna: {
        heading: 'Answers to our most frequently asked questions',
        q1: 'What is the starting price of ainfluencer campaing?',
        a1: 'answer',
        q2: 'What social platform do yourinfluencers use?',
        a2: 'answer',
        q3: 'Have you worked with anylarge reputable brands?',
        a3: 'answer',
        q4: 'What is your process of building a campaign?',
        a4: 'answer'
    },
    footer: {
        heading: 'Market your business',
        subheading1: 'Create a powerful online presence with our clever websites and landing pages.',
        button: 'Grow your business now!',
        subheading2: 'Our designs prioritize user experience to help you stand out from the competition.',
        question: 'Any Questions?',
        page: 'Pages',
        company: 'Company',
        subquestion: 'Feel free! Ask us anything related to our service',
        address: '107 Nguyen Van Linh St, D.7, HCM City',
        phone: '+84 973 898 494',
        email: 'hello@sitech.com',
        social: {
            facebook: 'Facebook',
            twitter: 'Twitter',
            instagram: 'Instagram'
        }
    }
};

export default en;
