import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function Tea(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Other-Drinks" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Trà</h1>
          <p>Chúng tôi có đầy đủ các loại trà thơm ngon bổ dưỡng cho sức khoẻ.</p>
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/hibiscus-vai.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/hibiscus-vai.png 500w, images/hibiscus-vai.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Trà Hibiscus Vải</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/tralai-taocam.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/tralai-taocam.png 500w, images/tralai-taocam.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Trà Lài Táo Cam</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/trasua-nhai.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/trasua-nhai.png 500w, images/trasua-nhai.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Sữa Nhài</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/trasua-dao.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/trasua-dao.png 500w, images/trasua-dao.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Sữa Đào</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/tradao-camxa.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/tradao-camxa.png 500w,
                images/tradao-camxa.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Đào Cam Xả</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/tradau-camdao.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/tradau-camdao.png 500w, images/tradau-camdao.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Trà Dâu Cam Đào</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/trahibiscus-vai.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/trahibiscus-vai.png 500w, images/trahibiscus-vai.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Vải Hoa Hồng</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/tralai-dacthom.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/tralai-dacthom.png 500w, images/tralai-dacthom.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Lài Đác Thơm</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/trasua-olong.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/trasua-olong.png 500w,
                images/trasua-olong.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Sen Vàng</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/trasua-nhai.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/trasua-nhai.png 500w, images/trasua-nhai.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Trà Olong</strong>
                <div className="select-field w-select">42.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default Tea;
