import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function VietnameseCoffee(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Coffee-Menu" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Cà Phê Việt Nam</h1>
          <p>Chúng tôi có đầy đủ các loại cà phê thơm ngon.</p>
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/cfvn.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/cfvn.png 500w, images/cfvn.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Cà Phê Việt</strong>
                <div className="select-field w-select">25.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cfdua.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/cfdua.png 500w, images/cfdua.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Cà Phê Cốt Dừa</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/bacxiu.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/bacxiu.png 500w, images/bacxiu.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Bạc Xỉu</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cacao.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/cacao.png 500w,
                images/cacao.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Ca Cao</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/cfdenkemmuoi.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/cfdenkemmuoi.png 500w,
                images/cfdenkemmuoi.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Cà Phê Đen Kem Muối</strong>
                <div className="select-field w-select">42.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default VietnameseCoffee;
