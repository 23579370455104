import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import Store from './store';

function IceBlended(props) {
  const { t } = props;

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    window.scrollTo(0, 0);
  });

  confetti({
    origin: { y: 0.5 },
    spread: 400,
    particleCount: 100,
    origin: { y: 0.4 },
  });

  return (
    <>
      <section id="Other-Drinks" className="section our-coffee wf-section">
        <div className="container">
          <h1 className="page-title">Đá xay</h1>
          <p>Chúng tôi có đầy đủ các loại đá xay.</p>
          <div className="w-layout-grid menu-grid">
            <div>
              <div className="menu-card">
                <img
                  src="images/chocolate.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/chocolate.png 500w, images/chocolate.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Chocolate</strong>
                <div className="select-field w-select">25.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/matcha.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/matcha.png 500w, images/matcha.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Matcha</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/oreo-cake.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/oreo-cake.png 500w, images/oreo-cake.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Oreo cake</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/chesse-cake.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/chesse-cake.png 500w,
                images/chesse-cake.png 928w
              "
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Chesse cake</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/caramel-macchiato-hatde-hanhnhan.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/caramel-macchiato-hatde-hanhnhan.png 500w, images/caramel-macchiato-hatde-hanhnhan.png 928w"
                  alt=""
                  className="menu-card-image"
                />
                {/* <div className="menu-card-title">Cà phê Việt</div> */}
              </div>
              <p className="small-p">
                <strong>Caramel hạnh nhân</strong>
                <div className="select-field w-select">25.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/dau.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="
                images/dau.png 500w, images/dau.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Dâu</strong>
                <div className="select-field w-select">39.000 đ</div>
              </p>
            </div>
            <div>
              <div className="menu-card">
                <img
                  src="images/chanhtuyet.png"
                  loading="lazy"
                  sizes="(max-width: 1439px) 169.99375915527344px, 199.99375915527344px"
                  srcSet="images/chanhtuyet.png 500w, images/chanhtuyet.png 928w"
                  alt=""
                  className="menu-card-image"
                />
              </div>
              <p className="small-p">
                <strong>Chanh tuyết</strong>
                <div className="select-field w-select">35.000 đ</div>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Store/>
    </>
  );
}

export default IceBlended;
